import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from 'react-player';
import Picture from '../picture/picture.module';
import styles from './video.module.scss';

export default class Video extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      previewOn: props.preview ? true : false,
      submittedAnalytics: {
        start: false,
        quarter: false,
        half: false,
        end: false
      }
    }
  }

  handleOnProgress = status => {
    const { url } = this.props;
    const { submittedAnalytics } = this.state;
    let action;

    if (status.played >= 0.5 && !submittedAnalytics['half']) {
      action = 'half';
    } else if (status.played >= 0.25 && !submittedAnalytics['quarter']) {
      action = 'quarter';
    }
    
    if (action) {
      this.submitEvent(action, url.substr(url.lastIndexOf('/') + 1));
      this.setState({ submittedAnalytics: { ...submittedAnalytics, [action]: true } })
    }
  }

  handleOnStart = () => {
    const { url } = this.props;
    const { start } = this.state.submittedAnalytics;
    if (!start) {
      this.submitEvent('start', url.substr(url.lastIndexOf('/') + 1));
      this.setState({ submittedAnalytics: { ...this.state.submittedAnalytics, start: true } })
    }
  }

  handleOnEnded = () => {
    const { url } = this.props;
    const { end } = this.state.submittedAnalytics;
    if (!end) {
      this.submitEvent('end', url.substr(url.lastIndexOf('/') + 1));
      this.setState({ submittedAnalytics: { ...this.state.submittedAnalytics, end: true } })
    }
  }

  submitEvent = (action, label) => {
    const { analytics } = this.props;
    if (analytics && analytics.event) {
      analytics.event({
        category: 'video',
        action: action,
        label: label
      })
    }
  }

  render() {
    const {
      analytics,
      url,
      playing,
      loop,
      controls,
      light,
      volume,
      muted,
      playbackRate,
      width,
      height,
      style,
      progressInterval,
      playsinline,
      pip,
      stopOnUnmount,
      wrapper,
      playIcon,
      config,
      preview
     } = this.props;
    
    const { previewOn } = this.state
    return (
      <span className={styles['video-player-module']}>
        { previewOn ? 
        <div style={{ position: 'relative' }}>
          <Picture className={styles['preview-image']}
            src={ preview }
            title='Video Preview' alt='Video Preview'
          />
          <button className={styles['play-button']} onClick={ () => this.setState({ previewOn: false }) }><span>PLAY</span></button>
        </div>
        :
        <VideoPlayer
          url={url}
          playing={playing}
          loop={loop}
          controls={controls}
          light={light}
          volume={volume}
          muted={muted}
          playbackRate={playbackRate}
          width={width}
          height={height}
          style={style}
          progressInterval={progressInterval}
          playsinline={playsinline}
          pip={pip}
          stopOnUnmount={stopOnUnmount}
          wrapper={wrapper}
          playIcon={playIcon}
          config={config}
          onProgress={ this.handleOnProgress }
          onStart={ this.handleOnStart }
          onEnded={ this.handleOnEnded }
          key={url}
          />
        }
      </span>
    );
  }
}

Video.propTypes = {
  analytics: PropTypes.object
}

Video.defaultProps = {
  width: '100%',
  height: 'auto',
}